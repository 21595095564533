import loadable from '@loadable/component';
import React, { useEffect } from 'react';

import { LoadingEmptyScreen } from '@Components/LoadingEmptyScreen/LoadingEmptyScreen';
import { scrollToTop } from '@Core/scroll-helpers/scrollToTop';

/**
 * When navigating from a non-generic page to a generic page, we want to scroll to the top of the
 * page before the generic page component finishes the lazy loading.
 */
const EmptyScreenWithScrollToTop = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return <LoadingEmptyScreen />;
};

export const LoadableGenericPage = loadable<Record<string, never>>(
  () => import('@Pages/generic/GenericPage'),
  {
    fallback: <EmptyScreenWithScrollToTop />,
  },
);
