import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';

import { FooterConfig, HeaderComponent } from '@Server/routes/Route';

interface PageLayoutContextValue {
  headerConfig: {
    headerComponents: HeaderComponent[];
    displayLogo: boolean;
    displayUsps: boolean;
    displayMenu: boolean;
    displayPhone: boolean;
    displayFavorite: boolean;
    displayAccount: boolean;
    displayHeader: boolean;
    displaySubHeader: boolean;
    displayHeaderBanner: boolean;
  };
  setHeaderConfig: (headerConfig?: HeaderComponent[]) => void;
  footerConfig: FooterConfig;
  setFooterConfig: (headerConfig?: FooterConfig) => void;
}

const PageLayoutContext = createContext<PageLayoutContextValue>({} as PageLayoutContextValue);

const defaultHeaderConfig: HeaderComponent[] = [
  'logo',
  'usps',
  'favorites',
  'account',
  'megamenu',
  'subHeader',
  'headerBanner',
];
const defaultFooterConfig: FooterConfig = { hidden: false };

export const PageLayoutContextProvider: React.FC<
  React.PropsWithChildren<{
    pageLayoutConfig: {
      header?: HeaderComponent[];
      footer?: FooterConfig;
    };
  }>
> = ({
  children,
  pageLayoutConfig: {
    header: initialHeader = defaultHeaderConfig,
    footer: initialFooter = defaultFooterConfig,
  },
}) => {
  const [header, setHeader] = useState<HeaderComponent[]>(initialHeader);
  const [footerConfig, setFooterConfig] = useState<FooterConfig>(initialFooter);

  const setHeaderConfigHandler = useCallback((config?: HeaderComponent[]) => {
    setHeader(config || defaultHeaderConfig);
  }, []);

  const setFooterConfigHandler = useCallback((footerConfig?: FooterConfig) => {
    setFooterConfig(footerConfig || defaultFooterConfig);
  }, []);

  const headerConfig = useMemo(
    () => ({
      headerComponents: header,
      displayHeader: !!header.length,
      displayLogo: header.includes('logo'),
      displayUsps: header.includes('usps'),
      displayMenu: header.includes('megamenu'),
      displayPhone: header.includes('telephone'),
      displayFavorite: header.includes('favorites'),
      displayAccount: header.includes('account'),
      displaySubHeader: header.includes('subHeader'),
      displayHeaderBanner: header.includes('headerBanner'),
    }),
    [header],
  );

  return (
    <PageLayoutContext.Provider
      value={{
        headerConfig,
        footerConfig,
        setHeaderConfig: setHeaderConfigHandler,
        setFooterConfig: setFooterConfigHandler,
      }}
    >
      {children}
    </PageLayoutContext.Provider>
  );
};

export const usePageLayoutContext = () => useContext(PageLayoutContext);
