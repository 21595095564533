import { EcommerceProduct, ProductType } from './types';
import { convertPenceToPounds } from '@Core/prices/magnitudes';

interface AddToCartData {
  price: number;
  name: string;
  quantity: number;
  id?: string | number;
  category?: ProductType;
  checkInDate?: string;
  checkOutDate?: string;
  numberOfAdults?: number;
  numberOfChildren?: number;
}

export const transformAddToCart = (product: EcommerceProduct) => {
  let transformedData: AddToCartData = {
    category: product.category,
    id: product.masterId,
    price: convertPenceToPounds(product.price as number),
    name: product.hotelName,
    quantity: 1,
  };

  if (product.list === 'HotelAndFlights') {
    transformedData = {
      ...transformedData,
      checkInDate: product.checkInDate,
      checkOutDate: product.checkOutDate,
      numberOfAdults: product.numberOfAdults,
      numberOfChildren: product.numberOfChildren,
    };
  }

  return transformedData;
};
