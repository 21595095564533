import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useHistory, matchPath } from 'react-router-dom';

import { useAppContext } from './contexts';
import { Route } from '@Server/routes/Route';

const RouteContext = createContext<Route>(null as unknown as Route);

export const useRouteContext = () => useContext(RouteContext);

export const getActiveRoute = (currentPath: string, routes: Route[]) =>
  routes.find(({ path, exact }) => {
    const match = matchPath(currentPath, { path });

    return !!match && (!exact || match.isExact);
  });

export const RouteContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { routes } = useAppContext();
  const { pathname } = useLocation();
  const { listen } = useHistory();
  const [currentRoute, setCurrentRoute] = useState(getActiveRoute(pathname, routes)!);

  useEffect(
    () =>
      listen(({ pathname }) => {
        setCurrentRoute(getActiveRoute(pathname, routes)!);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <RouteContext.Provider value={currentRoute}>{children}</RouteContext.Provider>;
};
