import React, { Component } from 'react';

import { AccommodationNotFoundError } from './client/AccommodationNotFoundError';
import { ContentNotFoundError } from './client/ContentNotFoundError';
import { captureClientError } from './errors';
import { AppContext } from '@Server/handlers/handle-contexts/types';

type ErrorStatus = AppContext['errorStatus'];

interface ErrorBoundaryProps {
  fallback: (errorStatus: Exclude<ErrorStatus, null>) => React.ReactNode;
  reportError?: boolean;
  errorStatus: ErrorStatus;
  path: string;
}

interface ErrorBoundaryState {
  errorStatus: ErrorStatus;
  path: string;
}

export class ErrorBoundary extends Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    reportError: false,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    const { errorStatus, path } = props;
    this.state = {
      errorStatus,
      path,
    };
  }

  static getDerivedStateFromProps(props: ErrorBoundaryProps, state: ErrorBoundaryState) {
    // Clearing the errorStatus after changing the path
    if (props.path !== state.path) {
      return {
        path: props.path,
        errorStatus: null,
      };
    }

    return null;
  }

  static getDerivedStateFromError(error: Error) {
    if (error instanceof ContentNotFoundError || error instanceof AccommodationNotFoundError) {
      return { errorStatus: 'not-found' };
    }

    return { errorStatus: 'exception' };
  }

  componentDidCatch(error: Error, info: { componentStack: string }) {
    if (this.props.reportError) {
      captureClientError(error, info);
    }
  }

  render() {
    const { children, fallback } = this.props;
    const { errorStatus } = this.state;

    return errorStatus === null ? children : fallback(errorStatus);
  }
}
