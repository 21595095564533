import { PageContainer, useRouterLinkContext } from '@loveholidays/design-system';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

import getHeaderBanner from './getHeaderBanner.gql';
import { CampaignContent, Query } from '@AuroraTypes';
import { usePageLayoutContext } from '@Contexts/PageLayoutContext';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';
import { Text } from '@UX/Text/Text';
import { ColorsPalette } from '@UX/themes/types';

interface BannerContentProps {
  message: CampaignContent;
}

const BannerContent: React.FC<BannerContentProps> = ({ message }) => (
  <PageContainer
    sx={{
      textAlign: 'center',
    }}
  >
    {message && (
      <Text
        sx={{
          color: message.colour as keyof ColorsPalette,
          fontWeight: 'bold',
          fontSize: ['s', 'm'],
        }}
      >
        {message.text}
      </Text>
    )}
  </PageContainer>
);

export const HeaderBanner: React.FC = () => {
  const {
    headerConfig: { displayHeaderBanner },
  } = usePageLayoutContext();
  const { useHref } = useRouterLinkContext();

  const { pathname } = useLocation();

  const [{ data }] = useQuery<Query>({
    query: getHeaderBanner,
    variables: { path: pathname },
  });

  if (!displayHeaderBanner) {
    return null;
  }

  if (!data?.Content?.headerBanner?.content?.length) {
    return null;
  }

  const { url, backgroundColour, content } = data.Content.headerBanner;
  const [message] = content;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(
    () => (
      <section
        data-id="campaign-banner"
        sx={{
          backgroundColor: backgroundColour as keyof ColorsPalette,
          paddingY: '3xs',
          position: 'relative',
          zIndex: 'header',
        }}
      >
        {url ? (
          <WithClickTracking action="campaign-banner">
            <a
              // eslint-disable-next-line react-hooks/rules-of-hooks
              href={useHref(url)}
              aria-label={message.text}
              sx={{ textDecoration: 'none' }}
            >
              <BannerContent message={message} />
            </a>
          </WithClickTracking>
        ) : (
          <BannerContent message={message} />
        )}
      </section>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [message, url, backgroundColour],
  );
};
