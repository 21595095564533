import React, { Fragment, ReactNode } from 'react';

import { useFeatureFlag } from './useFeatureFlag';

function isRenderProp(
  children: ReactNode | ((value: boolean) => ReactNode),
): children is (value: boolean) => ReactNode {
  return typeof children === 'function';
}

interface FeatureFlagProps {
  name: string;
  children?: ReactNode | ((value: boolean) => ReactNode);
}

export const FeatureFlag: React.FC<FeatureFlagProps> = ({ name, children }) => {
  const value = useFeatureFlag(name);

  if (isRenderProp(children)) {
    return children(value);
  }

  return value ? <Fragment>{children}</Fragment> : null;
};
