import { TranslationProvider } from '@loveholidays/phrasebook';
import React from 'react';

import { captureClientError } from '@Core/errors/errors';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { LoadableTranslations } from '@i18n/LoadableTranslations';

const TranslationProviderWrapper: React.FC<React.PropsWithChildren<{ locale: string }>> = ({
  children,
  locale,
}) => {
  const enableTranslationErrorsTracking = useFeatureFlag('TrackTranslationErrors');
  const enableThrowErrorsTracking = useFeatureFlag('ThrowTranslationErrors');

  return (
    <LoadableTranslations locale={locale}>
      {({ namespaces }: any) => (
        <TranslationProvider
          locale={locale}
          namespaces={namespaces}
          onError={(errorType, data) => {
            // Throws the error for debugging purpose.
            // Enabled on staging.
            if (enableThrowErrorsTracking) {
              throw new Error(
                `Failed to process the translation, errorType: ${errorType}, key: ${data.key}, argumentName: ${data.argumentName}`,
              );
            }

            if (!enableTranslationErrorsTracking) {
              return;
            }

            captureClientError(
              `Failed to process the translation, errorType: ${errorType}, key: ${data.key}, argumentName: ${data.argumentName}`,
              data,
            );
          }}
        >
          {children}
        </TranslationProvider>
      )}
    </LoadableTranslations>
  );
};

export default TranslationProviderWrapper;
