import React, { createContext, useContext } from 'react';
import UniversalCookie from 'universal-cookie';

import { CookieStorage } from './CookieStorage';
import {
  UserPreferencesAccessor,
  UserPreferencesConfiguration,
  CookieUserPreferencesConfiguration,
  UserPreferenceStorage,
  SessionStorageUserPreferencesConfiguration,
} from './interfaces';
import { LocalStorage } from './LocalStorage';
import { UserPreferencesAccessors, userPreferencesConfigurations } from './preferences';
import { useAppContext } from '../contexts';
import { SessionStorage } from '@Contexts/UserPreferencesContext/SessionStorage';
import { AppContext } from '@Server/handlers/handle-contexts/types';

/**
 * Get the user preferences object
 *
 * Usually you should use `useUserPreferences` and get the values from there. Only use this if you're
 * outside of the react component tree.
 *
 * Only works client side.
 *
 * Does not subscribe you to updates.
 */
export const getClientUserPreferences = () =>
  typeof window === 'undefined' ? undefined : window.userPreferences;

const UserPreferencesContext = createContext<UserPreferencesAccessors>(
  null as unknown as UserPreferencesAccessors,
);

export const useUserPreferences: () => UserPreferencesAccessors = () =>
  useContext(UserPreferencesContext);

function createAccessor<T extends any, U extends UserPreferenceStorage<any>>(
  configuration: UserPreferencesConfiguration<T>,
  appContext: AppContext,
  storage: U,
): UserPreferencesAccessor<T> {
  const { defaultValue, readTransform, writeTransform } = configuration;

  return {
    get(): T {
      const value = storage.read(configuration);

      if (typeof value !== 'undefined' && readTransform) {
        return readTransform(value);
      }
      if (typeof value === 'undefined' && defaultValue) {
        return defaultValue(appContext);
      }

      return value as T;
    },
    set(value: T, forceUpdate?: boolean) {
      storage.write(configuration, writeTransform ? writeTransform(value) : value, forceUpdate);
    },
    remove() {
      storage.remove(configuration);
    },
  };
}

function isCookieConfiguration(c: any): c is CookieUserPreferencesConfiguration<any> {
  return !!c.cookie;
}

function isSessionStorageConfiguration(
  c: any,
): c is SessionStorageUserPreferencesConfiguration<any> {
  return !!c.sessionStorageKey;
}

function initialiseContextValue(
  appContext: AppContext,
  cookieStorage: CookieStorage,
  localStorage: LocalStorage,
  sessionStorage: SessionStorage,
): UserPreferencesAccessors {
  return Object.entries(userPreferencesConfigurations).reduce((acc, [key, configuration]) => {
    let storage: LocalStorage | SessionStorage | CookieStorage = localStorage;

    if (isCookieConfiguration(configuration)) {
      storage = cookieStorage;
    }

    if (isSessionStorageConfiguration(configuration)) {
      storage = sessionStorage;
    }

    acc[key as keyof UserPreferencesAccessors] = createAccessor(configuration, appContext, storage);

    return acc;
  }, {} as UserPreferencesAccessors);
}

interface UserPreferencesContextProviderProps {
  cookies: UniversalCookie;
}

export const UserPreferencesContextProvider: React.FC<
  React.PropsWithChildren<UserPreferencesContextProviderProps>
> = ({ cookies, children }) => {
  const appContext = useAppContext();
  const cookieStorage = new CookieStorage(cookies, appContext);
  const localStorage = new LocalStorage(appContext);
  const sessionStorage = new SessionStorage();
  const initialValue = initialiseContextValue(
    appContext,
    cookieStorage,
    localStorage,
    sessionStorage,
  );
  if (typeof window !== 'undefined') {
    window.userPreferences = initialValue;
  }

  return (
    <UserPreferencesContext.Provider value={initialValue}>
      {children}
    </UserPreferencesContext.Provider>
  );
};
