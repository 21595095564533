import { useTranslation } from '@loveholidays/phrasebook';
import React, { createContext, useContext, useRef, useState } from 'react';
import { StateSelector } from 'zustand';

import { equalityFn } from './equalityFn';
import { createFavouritesStore, FavouritesStore } from './FavouritesStore';
import { createFlightAvailabilityStore, FlightAvailabilityStore } from './FlightAvailabilityStore';
import { createFlightSelectionStore, FlightSelectionStore } from './FlightSelectionStore';
import { createGenericPageStore, GenericPageStore } from './GenericPageStore';
import { SearchAvailabilityStore, createSearchAvailabilityStore } from './SearchAvailabilityStore';
import { createSearchResultsStore, SearchResultsStore } from './SearchResultsStore';
import {
  SearchSelectionStore,
  createSearchSelectionStore,
  SearchSelectionStoreInitialValue,
} from './SearchSelectionStore';
import { StoreContextValue } from './StoreContextValue';

export const StoreContext = createContext<StoreContextValue>({} as StoreContextValue);

export const useStoreContext = () => useContext(StoreContext);

export interface InitialValues {
  searchAvailability?: Partial<SearchAvailabilityStore>;
  searchSelection?: SearchSelectionStoreInitialValue;
  searchResults?: Partial<SearchResultsStore>;
  flightAvailability?: Partial<FlightAvailabilityStore>;
  flightSelection?: Partial<FlightSelectionStore>;
  favourites?: Partial<FavouritesStore>;
  genericPage?: Partial<GenericPageStore>;
}

export const StoreContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  const [stores] = useState<StoreContextValue>(() => ({
    searchAvailability: createSearchAvailabilityStore(),
    searchSelection: createSearchSelectionStore(),
    searchResults: createSearchResultsStore(),
    flightAvailability: createFlightAvailabilityStore(),
    flightSelection: createFlightSelectionStore(),
    favourites: createFavouritesStore(t),
    genericPage: createGenericPageStore(),
  }));

  return <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;
};

export const StoreOverride: React.FC<
  React.PropsWithChildren<{ initialValues?: InitialValues }>
> = ({ children, initialValues = {} }) => {
  const value = useStoreContext();
  const initialStateApplied = useRef<boolean>(false);

  if (!initialStateApplied.current) {
    // Unsubscribe all listeners from all stores
    Object.entries(value).forEach(([name, store]) => {
      if (
        ![
          // Except from the FavouritesStore because that is used outside the page layout in the header
          // @TODO: find a better solution for this
          'favourites',
          // Except generic page as this is need to avoid the issue with the store is not providing updates when the page is changed
          // The store is used on multiple pages, so it must not be destroyed.
          'genericPage',
        ].includes(name)
      ) {
        store.destroy();
      }
    });

    Object.entries(initialValues).forEach(([name, store]: [string, any]) => {
      value[name as keyof InitialValues]?.getState().setInitialValues(store);
    });

    initialStateApplied.current = true;
  }

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

export const useSearchAvailabilityStore: <U>(
  selector: StateSelector<SearchAvailabilityStore, U>,
) => U = (selector) => useContext(StoreContext).searchAvailability(selector, equalityFn);

export const useSearchSelectionStore: <U>(selector: StateSelector<SearchSelectionStore, U>) => U = (
  selector,
) => useContext(StoreContext).searchSelection(selector, equalityFn);

export const useSearchResultsStore: <U>(selector: StateSelector<SearchResultsStore, U>) => U = (
  selector,
) => useContext(StoreContext).searchResults(selector, equalityFn);

export const useFlightAvailabilityStore: <U>(
  selector: StateSelector<FlightAvailabilityStore, U>,
) => U = (selector) => useContext(StoreContext).flightAvailability(selector, equalityFn);

export const useFlightSelectionStore: <U>(selector: StateSelector<FlightSelectionStore, U>) => U = (
  selector,
) => useContext(StoreContext).flightSelection(selector, equalityFn);

export const useFavouritesStore: <U>(selector: StateSelector<FavouritesStore, U>) => U = (
  selector,
) => useContext(StoreContext).favourites(selector, equalityFn);

export const useGenericPageStore: <U>(selector: StateSelector<GenericPageStore, U>) => U = (
  selector,
) => useContext(StoreContext).genericPage(selector, equalityFn);
