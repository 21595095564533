import React, { Fragment, useMemo } from 'react';

import { LoadableAgentSiteSelector } from '@Components/AgentSiteSelector';
import { LoadableConsumableNotification } from '@Components/ConsumableNotification';
import { LoadableFooter } from '@Components/Footer/LoadableFooter';
import { LoadableInvisibleFrontierHeader } from '@Components/Frontier/LoadableInvisibleFrontierHeader';
import { Header } from '@Components/Header/Header';
import { HeaderBanner } from '@Components/HeaderBanner/HeaderBanner';
import { LoadableSiteSwitcher } from '@Components/SiteSwitcher';
import { useAppContext } from '@Contexts/contexts';
import { useRouteContext } from '@Contexts/RouteContext';
import { useUserPreferences } from '@Contexts/UserPreferencesContext/UserPreferencesContext';
import { ErrorBoundary } from '@Core/errors/ErrorBoundary';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { preLoadedLoadable as loadable } from '@Core/preLoadedLoadable';
import { GLOBAL_REPORTED_FEATURE_FLAGS } from '@Stores/FeatureFlag/FeatureFlagStore';

const ExceptionPage = loadable(() => import('@Pages/error/ExceptionPage'));
const NotFoundPage = loadable(() => import('@Pages/error/NotFoundPage'));

const ErrorPageBoundary: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { errorStatus } = useAppContext();
  const { path } = useRouteContext();

  return (
    <ErrorBoundary
      errorStatus={errorStatus}
      path={path}
      fallback={(errorStatus) => (
        <main data-id="main">
          {errorStatus === 'not-found' && <NotFoundPage />}
          {errorStatus === 'exception' && <ExceptionPage />}
        </main>
      )}
      reportError
    >
      {children}
    </ErrorBoundary>
  );
};

export const PageLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  for (const ffName of GLOBAL_REPORTED_FEATURE_FLAGS) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFeatureFlag(ffName);
  }
  const Wrapper = typeof window === 'undefined' ? Fragment : ErrorPageBoundary;

  const { site, isWebView, errorStatus, showSiteSwitcherForSite } = useAppContext();

  const { acceptedSites: acceptedSitesCookie, lvhNotification } = useUserPreferences();
  const acceptedSites = acceptedSitesCookie.get();
  const consumableNotification = lvhNotification.get();

  const head = useMemo(
    () =>
      errorStatus === null && (
        <Fragment>
          {site.siteCode === 'AGENT' && <LoadableAgentSiteSelector />}
          {!isWebView && (
            <Fragment>
              {showSiteSwitcherForSite && !acceptedSites.includes(site.siteCode) && (
                <LoadableSiteSwitcher siteCode={showSiteSwitcherForSite} />
              )}
              <HeaderBanner />
              <Header />
            </Fragment>
          )}
        </Fragment>
      ),
    [site.siteCode, isWebView, errorStatus, showSiteSwitcherForSite, acceptedSites],
  );

  const footer = useMemo(
    () => errorStatus === null && !isWebView && <LoadableFooter />,
    [errorStatus, isWebView],
  );

  return (
    <Wrapper>
      {isWebView && <LoadableInvisibleFrontierHeader />}
      {consumableNotification && <LoadableConsumableNotification />}
      {head}
      <main data-id="main">{children}</main>
      {footer}
    </Wrapper>
  );
};
